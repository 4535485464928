//* 부고문자페이지 조문객이 받는 페이지
import { FC, useEffect, useMemo } from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { bugoStateSelectorFamily } from '@shared/api/bugo/bugo.selector';
import { FeventStatus } from '@shared/api/fevent/fevent.interface';
import { ErrorFallback } from '@shared/components/ErrorFallback';
import ErrorNotFound from '@shared/containers/ErrorNotFound/ErrorNotFound';
import Splash from '@shared/containers/Splash/SplashAgency';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import _ from 'lodash';
import { ErrorBoundary } from 'react-error-boundary';
import { useParams } from 'react-router-dom';

import { FeventDoneAlertModal } from '../containers/Obituary/FeventDoneAlertModal';
import { FeventTodoAlertModal } from '../containers/Obituary/FeventTodoAlertModal';
import Obituary from '../containers/Obituary/Obituary';
import { useTitleHook } from '../hooks/useTitleHook';
import { useSplashAtom } from '../state/atom/splash.atom';

const BugoObituaryPage: FC = function BugoObituaryPage() {
  const { bugoId } = useParams();
  const bugoId_ = bugoId ?? 'undefined';
  const bugoState = useRecoilValue(bugoStateSelectorFamily(bugoId_));
  useTitleHook('', '', undefined, false);

  const feventDoneModalOpenId = 'bugoObituary-endFeventAlertModal';
  const setFeventDoneOpen = useSetRecoilState(
    simpleOpenAtomFamily(feventDoneModalOpenId),
  );
  const feventTodoModalOpenId = 'bugoObituary-todoFeventAlertModal';
  const setFeventTodoOpen = useSetRecoilState(
    simpleOpenAtomFamily(feventTodoModalOpenId),
  );

  useEffect(() => {
    if (bugoState.status === 'success' && bugoState.data) {
      if (bugoState.data.fevent.status === FeventStatus.Done) {
        setFeventDoneOpen(true);
      }
    }
  }, [bugoState.data, bugoState.status, setFeventDoneOpen]);

  const [showSplash, setShowSplash] = useRecoilState(useSplashAtom);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showSplash) {
      timeout = setTimeout(() => setShowSplash(false), 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [showSplash, setShowSplash]);

  const spalshContent = useMemo(() => {
    if (bugoState.status === 'success' && bugoState.data) {
      const bugo = bugoState.data;
      const bugoAgencyIndex = bugo.fevent.bugoBrandAgencyIndex;

      const logoUrl =
        !_.isNil(bugoAgencyIndex) && bugo.bugoBrand?.bugoAgencyList
          ? bugo.bugoBrand.bugoAgencyList[bugoAgencyIndex]?.headerImage?.url
          : bugo.bugoBrand?.bugoAgency?.headerImage?.url;
      return (
        <Splash isLoading={showSplash} logoUrl={fileBucketUrl(`${logoUrl}`, 'w400')} />
      );
    }
  }, [bugoState.data, bugoState.status, showSplash]);
  useEffect(() => {
    if (bugoState.status === 'success' && bugoState.data) {
      const enterance = bugoState.data.fevent.deceasedInfo.enterance;
      const now = new Date();

      if (enterance?.date) {
        if (enterance.timeDefined) {
          if (enterance.date > now) {
            setFeventTodoOpen(true);
          }
        } else {
          const temp = new Date(enterance.date);
          temp.setHours(0);
          temp.setMinutes(0);
          temp.setSeconds(0);
          if (temp > now) {
            setFeventTodoOpen(true);
          }
        }
      } else {
        return;
      }
    }
  }, [bugoState.data, bugoState.status, setFeventDoneOpen, setFeventTodoOpen]);

  const obituary = useMemo(() => {
    if (bugoState.status === 'success' && bugoState.data) {
      if (bugoState.data.fevent?.meta?.isDeleted) {
        return <ErrorNotFound announceString="해당 부고 파기됨" />;
      } else {
        return (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Obituary bugo={bugoState.data} />
            <FeventDoneAlertModal openId={feventDoneModalOpenId} />
            <FeventTodoAlertModal openId={feventTodoModalOpenId} />
          </ErrorBoundary>
        );
      }
    }
  }, [bugoState.data, bugoState.status]);

  return (
    <div className="max-w-inherit select-none">
      {spalshContent}
      {obituary}
    </div>
  );
};

export default BugoObituaryPage;
