import { atom, selector } from 'recoil';

import { ShopOrder } from '@shared/api/shopOrder/shopOrder.interface';
import { QueryStatus } from 'react-query';

export const adminShopOrderListStatusAtom = atom<QueryStatus>({
  key: 'adminShopOrderListStatusAtom',
  default: 'idle',
});

export const adminShopOrderListDataAtom = atom<ShopOrder[] | null>({
  key: 'adminShopOrderListDataAtom',
  default: null,
});

export const adminShopOrderListStateSelector = selector<{
  status: QueryStatus;
  data: ShopOrder[] | null;
}>({
  key: 'adminShopOrderListStateSelector',
  get: ({ get }) => {
    return {
      status: get(adminShopOrderListStatusAtom),
      data: get(adminShopOrderListDataAtom),
    };
  },
});
