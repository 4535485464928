import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { useAdminShopOrderListHook } from '../hooks/useAdminShopOrderHook';
import { adminShopOrderListStateSelector } from '../state/atom';

export const AdminShopOrderPage: FC = function () {
  /**
   * 1. shop-order 연도별로 가져올것 일단 항상 작년
   */
  useAdminShopOrderListHook();
  const shopOrderListState = useRecoilValue(adminShopOrderListStateSelector);

  const cashShopOrderList = useMemo(() => {
    const shopOrderList = shopOrderListState.data;

    if (shopOrderList) {
      const kakaoList = shopOrderList.filter((shopOrder) => {
        return shopOrder.paymentDetail.method === '카카오머니';
      });

      const vBankList = shopOrderList.filter((shopOrder) => {
        return (
          shopOrder.paymentDetail.method === 'vbank' ||
          shopOrder.paymentDetail.method === '가상계좌'
        );
      });

      const cashAccountList = shopOrderList.filter((shopOrder) => {
        return shopOrder.paymentDetail.method === '계좌 입금';
      });

      const paycoList = shopOrderList.filter((shopOrder) => {
        return shopOrder.paymentDetail.method === '페이코포인트';
      });

      const cardList = shopOrderList.filter((shopOrder) => {
        return shopOrder.paymentDetail.method === '카드';
      });

      return {
        kakaoList: groupByMonth(kakaoList),
        vBankList: groupByMonth(vBankList),
        cashAccountList: groupByMonth(cashAccountList),
        paycoList: groupByMonth(paycoList),
        cardList: groupByMonth(cardList),
      };
    }
  }, [shopOrderListState.data]);

  const methodSet: any[] = [];

  shopOrderListState.data?.forEach((order) => {
    if (methodSet.indexOf(order.paymentDetail.method) === -1) {
      methodSet.push(order.paymentDetail.method);
    }
  });

  return (
    <div>
      <p className="font-medium">
        이 페이지는 관리자용 통계(연말정산 등)에 필요한 다양한 자료를 얻기위한 페이지
      </p>
    </div>
  );
};

function groupByMonth(data: any) {
  return data.reduce((acc: any, item: any) => {
    // purchasedAt을 Date 객체로 변환
    const date = new Date(item.paymentDetail.purchasedAt);

    // KST 타임존 기준으로 월 추출
    const options: Intl.DateTimeFormatOptions = {
      timeZone: 'Asia/Seoul',
      month: 'numeric',
    };
    const month = new Intl.DateTimeFormat('en-US', options).format(date);

    // 그룹에 추가
    if (!acc[month]) {
      acc[month] = {
        totalPurchases: 0,
        totalAmount: 0,
      };
    }

    acc[month].totalPurchases += 1;
    acc[month].totalAmount += item.paymentDetail.paymentByCash;

    return acc;
  }, {});
}
