import { fetcher, queryString, useRecoilQuery } from '@shared/hooks/recoil-query';
import { PaymentState } from '@shared/types';

import { adminShopOrderListDataAtom, adminShopOrderListStatusAtom } from '../state/atom';

// 현재 날짜 얻기
const startOf2024KST = new Date('2024-01-01T00:00:00+09:00'); // 2023-12-31T15:00:00.000Z
const endOf2024KST = new Date('2025-01-01T00:00:00+09:00'); // 2024-12-31T15:00:00.000Z

export const shopOrderListHookUrl = `/admin/shop-order/?${queryString({
  select: ['paymentDetail'],
  filter: {
    'paymentDetail.status': { $in: [PaymentState.PAID, PaymentState.CONFIRMED] },
    'paymentDetail.purchasedAt': { $gte: startOf2024KST, $lt: endOf2024KST },
  },
})}`;

export const useAdminShopOrderListHook = function () {
  useRecoilQuery(
    adminShopOrderListStatusAtom,
    adminShopOrderListDataAtom,
    shopOrderListHookUrl,
    fetcher,
  );
};
