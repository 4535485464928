import { atom } from 'recoil';

import { CheckBoxFilter } from '@shared/components/CheckboxFilter';
import dayjs from 'dayjs';

export const sellerAccountBugoBrandFilterAtom = atom<CheckBoxFilter>({
  key: 'sellerAccountBugoBrandFilterAtom',
  default: {},
});

export const sellerAccountSelectYearAtom = atom<dayjs.Dayjs | null>({
  key: 'sellerAccountSelectYearAtom',
  default: dayjs(),
});
