//* 상주님이 받는 부고문자 페이지 (개인정보 동의, 계좌 등록이 가능함)

import { FC, useEffect, useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { bugoByMemberIdStateSelectorFamily } from '@shared/api/bugo/bugo.selector';
import { FeventStatus } from '@shared/api/fevent/fevent.interface';
import { Loading } from '@shared/components/Loading';
import ErrorNotFound from '@shared/containers/ErrorNotFound/ErrorNotFound';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useParams, useSearchParams } from 'react-router-dom';

import MemberObituary from '../containers/MemberObituary/MemberObituary';
import { useTitleHook } from '../hooks/useTitleHook';
import { bugoNavRightPhoneNumberAtom } from '../state';

const MemberObituaryPage: FC = function MemberObituaryPage() {
  const setRightPheneNumber = useSetRecoilState(bugoNavRightPhoneNumberAtom);
  useTitleHook(
    '부고 공유하기(상주님 전용)',
    '담당자 연결',
    undefined,
    true,
    undefined,
    false,
  );

  const { memberId } = useParams();
  const [serachParams] = useSearchParams();
  const memberId_ = memberId ?? 'undefined';
  const method = serachParams.get('method');

  const bugoByMemberIdState = useRecoilValue(
    bugoByMemberIdStateSelectorFamily(memberId_),
  );
  const feventDoneModalOpenId = 'memberObituary-endFeventAlertModal';
  const setFeventDoneOpen = useSetRecoilState(
    simpleOpenAtomFamily(feventDoneModalOpenId),
  );
  const feventTodoModalOpenId = 'memberObituary-todoFeventAlertModal';
  const setFeventTodoOpen = useSetRecoilState(
    simpleOpenAtomFamily(feventTodoModalOpenId),
  );

  useEffect(() => {
    if (bugoByMemberIdState.status === 'success' && bugoByMemberIdState.data) {
      if (bugoByMemberIdState.data.fevent.status === FeventStatus.Done) {
        setFeventDoneOpen(true);
      }
    }
  }, [bugoByMemberIdState.data, bugoByMemberIdState.status, setFeventDoneOpen]);

  useEffect(() => {
    if (bugoByMemberIdState.status === 'success' && bugoByMemberIdState.data) {
      const enterance = bugoByMemberIdState.data.fevent.deceasedInfo.enterance;
      const now = new Date();

      if (enterance?.date) {
        if (enterance.timeDefined) {
          if (enterance.date > now) {
            setFeventTodoOpen(true);
          }
        } else {
          const temp = new Date(enterance.date);
          temp.setHours(0);
          temp.setMinutes(0);
          temp.setSeconds(0);
          if (temp > now) {
            setFeventTodoOpen(true);
          }
        }
      } else {
        return;
      }
    }
  }, [
    bugoByMemberIdState.data,
    bugoByMemberIdState.status,
    setFeventDoneOpen,
    setFeventTodoOpen,
  ]);

  const obituary = useMemo(() => {
    if (bugoByMemberIdState.status === 'success' && bugoByMemberIdState.data) {
      if (bugoByMemberIdState.data.fevent?.meta?.isDeleted) {
        return <ErrorNotFound announceString="해당 부고 파기됨" />;
      } else {
        const bugo = bugoByMemberIdState.data;
        setRightPheneNumber(bugo.user.info.phoneNumber);
        return (
          <div className="max-w-inherit">
            <MemberObituary bugo={bugoByMemberIdState.data} method={method ?? ''} />
          </div>
        );
      }
    } else {
      return (
        <div className="center-box h-screen-15">
          <Loading />
        </div>
      );
    }
  }, [bugoByMemberIdState.data, bugoByMemberIdState.status, method, setRightPheneNumber]);

  return <div className="max-w-inherit">{obituary}</div>;
};

export default MemberObituaryPage;
