//* 발인완료된 상을 알려주는 모달 (부고문자에 사용됨)

import { ReactComponent as BlackRibbonSvg } from '/src/assets/svg/black-ribbon.svg';

import Modal from '@shared/components/Modal';

interface Props {
  openId: string;
}

export const FeventTodoAlertModal = function FeventTodoAlertModal({ openId }: Props) {
  return (
    <Modal openId={openId} position="center">
      <div className="space-y-4 px-4 text-center">
        <div className="center-box">
          <BlackRibbonSvg />
        </div>
        <div className="center-box gap-4">
          <p className="text-xl font-medium text-gray-700">
            해당 상가는
            <br /> <span className="font-bold">입실 준비중 </span>입니다.
          </p>
        </div>
      </div>
    </Modal>
  );
};
