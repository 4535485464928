//* 부고문자페이지 조문객이 받는 페이지
import { FC, useEffect, useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { bugoStateSelectorFamily } from '@shared/api/bugo/bugo.selector';
import { FeventStatus } from '@shared/api/fevent/fevent.interface';
import { ErrorFallback } from '@shared/components/ErrorFallback';
import { Loading } from '@shared/components/Loading';
import ErrorNotFound from '@shared/containers/ErrorNotFound/ErrorNotFound';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { ErrorBoundary } from 'react-error-boundary';
import { useParams } from 'react-router-dom';

import { FeventDoneAlertModal } from '../containers/Obituary/FeventDoneAlertModal';
import { FeventTodoAlertModal } from '../containers/Obituary/FeventTodoAlertModal';
import Obituary from '../containers/Obituary/Obituary';
import { useTitleHook } from '../hooks/useTitleHook';

const BugoObituaryPreviewPage: FC = function BugoObituaryPreviewPage() {
  const { bugoId } = useParams();
  const bugoId_ = bugoId ?? 'undefined';
  const bugoState = useRecoilValue(bugoStateSelectorFamily(bugoId_));
  useTitleHook('부고미리보기', ' ', undefined);

  const feventDoneModalOpenId = 'bugoObituary-endFeventAlertModal';
  const setFeventDoneOpen = useSetRecoilState(
    simpleOpenAtomFamily(feventDoneModalOpenId),
  );

  const feventTodoModalOpenId = 'bugoObituary-todoFeventAlertModal';
  const setFeventTodoOpen = useSetRecoilState(
    simpleOpenAtomFamily(feventTodoModalOpenId),
  );

  useEffect(() => {
    if (bugoState.status === 'success' && bugoState.data) {
      if (bugoState.data.fevent.status === FeventStatus.Done) {
        setFeventDoneOpen(true);
      }
    }
  }, [bugoState.data, bugoState.status, setFeventDoneOpen]);

  useEffect(() => {
    if (bugoState.status === 'success' && bugoState.data) {
      const enterance = bugoState.data.fevent.deceasedInfo.enterance;
      const now = new Date();

      if (enterance?.date) {
        if (enterance.timeDefined) {
          if (enterance.date > now) {
            setFeventTodoOpen(true);
          }
        } else {
          const temp = new Date(enterance.date);
          temp.setHours(0);
          temp.setMinutes(0);
          temp.setSeconds(0);
          if (temp > now) {
            setFeventTodoOpen(true);
          }
        }
      } else {
        return;
      }
    }
  }, [bugoState.data, bugoState.status, setFeventDoneOpen, setFeventTodoOpen]);

  const obituary = useMemo(() => {
    if (bugoState.status === 'success' && bugoState.data) {
      if (bugoState.data.fevent?.meta?.isDeleted) {
        return <ErrorNotFound announceString="해당 부고 파기됨" />;
      } else {
        return (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Obituary bugo={bugoState.data} />
            <FeventDoneAlertModal openId={feventDoneModalOpenId} />
            <FeventTodoAlertModal openId={feventTodoModalOpenId} />
          </ErrorBoundary>
        );
      }
    } else {
      return (
        <div className="center-box h-screen-15">
          <Loading />
        </div>
      );
    }
  }, [bugoState.data, bugoState.status]);

  return <div className="max-w-inherit select-none">{obituary}</div>;
};

export default BugoObituaryPreviewPage;
